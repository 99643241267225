import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DashboardCoreModule } from '@x/dashboard/core';
import { DashboardFormModule } from '@x/dashboard/form';
import { DashboardGridModule } from '@x/dashboard/grid';
import { AuthRoleFormDialogComponent } from './components/auth-role-form-dialog/auth-role-form-dialog.component';
import { AuthDialogService } from './services/auth-dialog.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DashboardCoreModule,
    DashboardFormModule,
    DashboardGridModule,
  ],
  declarations: [AuthRoleFormDialogComponent],
})
export class AuthRoleSharedModule {
  static forRoot(): ModuleWithProviders<AuthRoleSharedModule> {
    return {
      ngModule: AuthRoleSharedModule,
      providers: [AuthDialogService],
    };
  }
}
