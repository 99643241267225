import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthRoleService } from '@x/ecommerce/domain-client';
import {
  AuthPermissionItemCollectionProvider,
  ChannelItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { CreateAuthRoleInput, UpdateAuthRoleInput } from '@x/schemas/ecommerce';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface AuthRoleFormDialogData {
  id?: number;
}

export interface AuthRoleFormDialogResult {
  data?: {
    id: number;
  };
}

@Component({
  selector: 'x-auth-role-form-dialog',
  templateUrl: 'auth-role-form-dialog.component.html',
  styleUrls: ['auth-role-form-dialog.component.scss'],
  providers: [],
})
export class AuthRoleFormDialogComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  channelIdsFormControl = new FormControl<number[]>([]);
  allChannelsFormControl = new FormControl<boolean>(true, [Validators.required]);
  formGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required]),
    permissions: new UntypedFormControl([], [Validators.required]),
    channelIds: this.channelIdsFormControl,
    allChannels: this.allChannelsFormControl,
  });

  Providers = {
    AuthPermissionItemCollectionProvider,
    ChannelItemCollectionProvider,
  };

  constructor(
    public dialog: MatDialogRef<AuthRoleFormDialogComponent, AuthRoleFormDialogResult>,
    private authRoleService: AuthRoleService,
    @Inject(MAT_DIALOG_DATA)
    public data?: AuthRoleFormDialogData,
  ) {}

  ngOnInit(): void {
    if (this.data?.id) {
      this.authRoleService
        .fetchById(this.data.id)
        .pipe(
          takeUntil(this._destroy$),
          tap((data) => {
            const formValue = {
              name: data.name,
              permissions: data.permissions,
              channelIds: data.channels.map((c) => c.id),
              allChannels: data.allChannels,
            };
            this.formGroup.setValue(formValue, { emitEvent: true });
          }),
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  async submit() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) return;

    if (this.data?.id) {
      const input: UpdateAuthRoleInput = {
        id: this.data.id,
        name: this.formGroup.get('name')?.value ?? null,
        permissions: this.formGroup.get('permissions')?.value ?? null,
        channelIds: this.channelIdsFormControl.value ?? [],
        allChannels:
          this.allChannelsFormControl.value ??
          !(this.channelIdsFormControl.value?.length ?? 0 === 0),
      };
      await firstValueFrom(
        this.authRoleService.update(input).pipe(
          takeUntil(this._destroy$),
          tap((data) => this.dialog.close({ data })),
        ),
      );
    } else {
      const input: CreateAuthRoleInput = {
        name: this.formGroup.get('name')?.value,
        permissions: this.formGroup.get('permissions')?.value,
        channelIds: this.channelIdsFormControl.value ?? [],
        allChannels:
          this.allChannelsFormControl.value ??
          !(this.channelIdsFormControl.value?.length ?? 0 === 0),
      };
      await firstValueFrom(
        this.authRoleService.create(input).pipe(
          takeUntil(this._destroy$),
          tap((data) => this.dialog.close({ data })),
        ),
      );
    }
  }

  close() {
    this.dialog.close();
  }
}
