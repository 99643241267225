<x-dashboard-form-layout>
  <x-form-panel>
    <div class="form-inner" [formGroup]="formGroup">
      <h2 mat-dialog-title>{{ data?.id ? 'Update' : 'Create' }} User Role</h2>

      <div class="d-flex">
        <mat-form-field class="flex-grow-1">
          <mat-label>Role Name</mat-label>
          <input formControlName="name" matInput />
        </mat-form-field>
      </div>

      <div class="d-flex all-channels-wrapper">
        <mat-radio-group formControlName="allChannels">
          <mat-radio-button color="primary" [value]="true">Enable in all channels</mat-radio-button>
          <mat-radio-button color="primary" [value]="false">
            Enable in specific channels
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="d-flex" *ngIf="allChannelsFormControl.value !== true">
        <mat-form-field class="flex-grow-1" appearance="outline">
          <mat-label>Specify Channels</mat-label>
          <x-data-autocomplete
            [provider]="Providers.ChannelItemCollectionProvider"
            [multiple]="true"
            placeholder="Search Channels"
            formControlName="channelIds"
          ></x-data-autocomplete>
        </mat-form-field>
      </div>

      <div class="d-flex">
        <mat-form-field class="flex-grow-1" appearance="outline">
          <mat-label>Permissions</mat-label>
          <x-data-autocomplete
            [provider]="Providers.AuthPermissionItemCollectionProvider"
            [multiple]="true"
            placeholder="Search Permissions"
            formControlName="permissions"
          ></x-data-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </x-form-panel>
</x-dashboard-form-layout>

<div mat-dialog-actions align="end">
  <button mat-button color="accent" type="button" (click)="close()">Close</button>
  <button
    mat-button
    color="primary"
    type="button"
    (click)="submit()"
    [disabled]="formGroup.invalid || formGroup.pending"
  >
    {{ data?.id ? 'Update' : 'Create' }}
  </button>
</div>
