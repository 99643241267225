import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AuthRoleFormDialogComponent,
  AuthRoleFormDialogData,
  AuthRoleFormDialogResult,
} from '../components/auth-role-form-dialog/auth-role-form-dialog.component';

@Injectable()
export class AuthDialogService {
  constructor(private dialogs: MatDialog) {}

  openAuthRoleCreateDialog() {
    return this.dialogs.open<
      AuthRoleFormDialogComponent,
      AuthRoleFormDialogData,
      AuthRoleFormDialogResult
    >(AuthRoleFormDialogComponent, {});
  }

  openAuthRoleUpdateDialog(id: number) {
    return this.dialogs.open<
      AuthRoleFormDialogComponent,
      AuthRoleFormDialogData,
      AuthRoleFormDialogResult
    >(AuthRoleFormDialogComponent, {
      data: {
        id,
      },
    });
  }
}
